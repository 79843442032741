.flt {
    float: left;
    width: 100%;
}

.popup-box {
    position: fixed;
    /* background: transparent; */
    background: #3e3c3cd4;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.box1 {
    padding: 15px 30px;
    position: relative;
    width: 35%;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 5px;
    margin: 50px auto;
    transform: translateX(-50%);
    left: 50%;
    overflow: auto;
}

.col-sm-6.text {
    font-weight: 500;
    font-size: 19px;
    line-height: 37px;
    color: #00415A;
}

.text span {
    margin-right: 5px;
}

.buttn button {
    float: right;
    border: navajowhite;
}

.list {
    margin-top: 25px;
}

.list ul {
    list-style-type: none;
}

.list li {

    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #454545;
    margin-bottom: 12px;
}

.list ul li span {
    margin-right: 8px;
}
@media (min-width: 320px)and (max-width:767px){

.list li {

    font-size: 11px !important;
 
}
}

@media (max-width: 767px) {
    .box1 {
        width: 90% !important;
        padding: 15px !important;
    }

    .box1 ul {
        padding: 0px !important;
        margin: 0px !important;
    }
    .box1 .row {
        align-items: center;
    }
    .box1 .row .col-sm-6 {
        float: left;
        width: 50%;
    }
}

@media (min-width: 768px) {
    .box1 {
        width: 35% !important;
    }
}