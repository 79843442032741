
.flt {
    float: left;
    width: 100%;
  }
   

.contact {
    background: #D6D6D6;
    border-top: 1px solid #F27F0C;
    /* padding: 10px 0px; */
    padding: 5px 0px;
  }
  .contact ul {
    list-style-type: none;
      padding: 0px;
      margin: 0px;
      text-align: center;
  
  }
  .contact ul li {
    margin: 0px 31px;
    display: inline;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #00415A;
  
  }
  
  
  .footer {
    background: #00415A;
    padding: 3px 0px;
  }
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }
  
  
  