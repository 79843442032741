.logoutButton {
  float: right;
  margin-right: 15px;
}

.logout_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.form1 .form-group, .form2 .form-group{
  margin-bottom: 5px;
  min-height: 110px;
}
.textdesign {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-top: 7px;
}


.back {

  height: 200px;
}

.back img {
  width: 100%;
}

.back_head {
  padding: 30px 0px;
  color: hsl(0, 0%, 100%);
  font-weight: 700;
  font-size: 24px;
  line-height: 43px;
}

.form_container {
  margin: -240px 0px 30px 0px;
  padding: 50px;
}

.form1 {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(242, 127, 12, 0.22);
  box-shadow: 0px 0px 15px rgb(0 65 90 / 15%);
  border-radius: 8px;
  padding: 15px 20px 54px 20px;
}

.form1 form h3 {
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: rgb(0, 65, 90);
  margin-bottom: 20px;
}

.form1 form label,
.form2 label {

  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  align-items: center;
  text-transform: uppercase;
  color: rgb(0, 65, 90);
  margin-bottom: 5px;

}
.form-group {
  margin-bottom: 25px;
}

.form1 form input placeholder {
  font-size: 5px;
}

.form1 input,
.form2 input,
.form1 select,
.form2 select,
.form1 textarea,
.form2 textarea {
  border: 1px solid rgb(242, 127, 12);
  border-radius: 5px;
  width: 100%;
  background: rgb(245, 245, 245);
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 24px;
  outline: none;
}

.form2 {
  background: rgb(255, 255, 255);
  border: 1px solid rgba(242, 127, 12, 0.22);
  box-shadow: 0px 0px 15px rgb(0 65 90 / 15%);
  border-radius: 8px;
  padding: 15px 20px;
}
.form2 h3 {
  font-weight: 600;
  font-size: 25px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: rgb(0, 65, 90);
  margin-bottom: 20px;
}
.form2 form  h3 {
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: rgb(0, 65, 90);
  margin-bottom: 20px;
}

.form select {
  width: 100%;
}

.form-group.form_add {
  margin-bottom: 20px;
}
.form-group.GST {
  margin-top: -50px;
}
.Fileup {
    margin-top: 10px;
}

.payment {
  padding: 10px 30px;
}
.star{
/* abc */
    color: rgba(197, 11, 11, 0.722);

}

.sub_txt {
  text-align: right;
  padding-right: 20px;

}

.sub_txt button {
  background: rgb(242, 127, 12);
  border-radius: 6px;
  padding: 10px 35px;
  border: none;
  color: rgb(255, 255, 255);
}


.checkbox label {
  width: auto;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  margin-bottom: 0px;
  margin-left: 10px;
  text-transform: none !important;
  margin-bottom: 0px !important;
}

.checkbox {
  display: flex;
  align-items: flex-start;
}

.checkbox span {
  color: #F27F0C;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}



.form1 input#check {
  width: auto;

  margin-top: 4px;
}


@media (min-width: 320px) and (max-width: 767px){
.header_text span {
    font-size: 15px !important;
}
.main_container {
  padding: 15px 20px ;
}

.form-group.docment {
  margin-top: 30px !important;
}

.col-sm-6.reln {
  margin-top: 10px !important;
}
.form1 form label, .form2 label {
  font-size: 12px;
  line-height: 15px;
}

.form1 .form-group, .form2 .form-group {
  margin-bottom: -10px !important;
  min-height: 110px;
}
.sub_txt button {
  margin-top: 20px !important;
}

.form-group.docment {
  margin-top: 15px;
}
.col-sm-6.reln {
  margin-top: 10px !important;
}
.form-group.checkbox {
  margin-top: 20px !important;
}
.form1 form h3 {
 padding: 0px 0px 15px 0px !important;
}


.form1 input,
.form2 input,
.form1 select,
.form2 select,
.form1 textarea,
.form2 textarea {
  padding: 10px 10px !important;
}

.form_container {
  padding: 50px 20px;
}
.back_head {

  font-size: 15px !important;
}
.form1 input, .form2 input, .form1 select, .form2 select, .form1 textarea, .form2 textarea {
  font-size: 14px !important;
}
.form1 input, .form2 input, .form1 select, .form2 select, .form1 textarea, .form2 textarea {

  font-size: 14px !important;
  
}
}


@media (max-width: 480px)
{
  .checkbox label {
    font-size: 12px !important;
  }
}

@media(min-width:768px) and (max-width: 991px){
  .form1 form h3 {
 
    font-size: 20px;
    line-height: 25px;
  }
  .form1 form label, .form2 label {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
  }

  .form1 input, .form2 input, .form1 select, .form2 select, .form1 textarea, .form2 textarea {
    border: 1px solid #f27f0c;
    border-radius: 5px;
    width: 100%;
    background: #F5F5F5;
    font-weight: 300;
    font-size: 12px;
    line-height: 22px;
    padding: 10px 10px;
}
.sub_txt button {
  
    padding: 10px 18px !important;
}
}
