@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');



body {
    font-family: 'Roboto', sans-serif;
}

.flt {
    float: left;
    width: 100%;
}

ul {
    padding: 0px;
    margin: 0px;
}

.login_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url(../../../../src/assets/images/mahe-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.login_frame {
    border-radius: 0px 0px 10px 10px;
    padding: 34px 0px;
    background-image: url(../../../../src/assets/images/login-frame.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.login_box {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(242, 127, 12);
    border-radius: 19px;
    padding: 15px;
    width: 470px;
}

.login_top {
    border-radius: 10px !important;
    display: block;
}

.login_logo {
    border-radius: 10px 10px 0px 0px;
    padding: 26px 0px;
    border: 1px solid rgb(242, 127, 12);
    text-align: center;
}

.login_btm {
    padding: 16px 0px;
}

.login_btm form input.form-control {
    background: rgb(245, 245, 245);
    border-radius: 6px;
    height: 60px;
    margin-bottom: 25px;
}

.login_btm label {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: rgb(0, 65, 90);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login_otp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}


.login_otp input:last-child {
    margin-right: 0px !important;
}

.login_btm label a {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: rgb(242, 127, 12);
}

.login_head {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    margin-bottom: 13px;
}

.login_roll_no {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
}

.login_btn button {
    font-size: 18px;
    background: rgb(242, 127, 12);
    border-radius: 6px;
    border: none;
    color: rgb(255, 255, 255);
    width: 100%;
    padding: 16px 0px;
}

.login_otp input {
    text-align: center;
    border: none;
    background: rgb(245 245 245);
    border-radius: 6px;
    width: calc(100% - 50px);
    margin-right: 40px !important;
    height: 60px !important;
}

.resend_otp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
}

.resend {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: rgb(242, 127, 12);
}

@media (min-width:320px) and (max-width:479px) {
    .login_box {
        width: 92%;
    }
    .login_otp input {
        width: 40%;
        margin-right: 15px !important;
    }
    .login_otp input.form-control {
        width: 40px !important;
        margin-right: 10px !important;
        height: 42px !important;
    }

    .login_head {
        font-weight: 500;
        font-size: 16px;
    }

    .login_btm form input.form-control::placeholder{
        font-size: 12px;
    }
}
@media (min-width:480px) and (max-width:639px) {
    .login_box {
        width: 92%;
    }
}