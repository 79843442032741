.flt {
  float: left;
  width: 100%;
}


.main_container {
  padding: 15px 50px;
  border-bottom: 2px solid rgb(242, 127, 12);
}


/* .header .row {
    align-items: right  !important;
  } */

.header_text {
  text-align: right;
  padding: 10px 0px;
  text-transform: uppercase;
  color: #00415A;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header_text button {
  border: none;
  padding: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.header_text button img {
  
  margin-right: 5px;
}

.note_cont {
  border: 1px solid rgb(255, 235, 215) !important;
  border-radius: 5px !important;
 
}

@media (min-width:320px) and (max-width:767px) {

  .logo img {
    width: 100%;
  }

  .main_container {
    padding: 15px 15px !important;
    border-bottom: 2px solid #F27F0C;
  }

  .header .row .col-sm-6 {
    float: left;
    width: 50%;
    display: flex;
    align-items: center;
  }

  .header_text button {
    border: none;
    padding: 0px;
    background-color: transparent;
  }

  .header_text span {
    font-size: 8px;
  }

  .back_head {

    font-weight: 600;
    font-size: 12px;
    line-height: 25px;
  }


}