.flt {
    float: left;
    width: 100%;
}

.popup-box {
    position: fixed;
    /* background: transparent; */
    background: #3e3c3cd4;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.box {
   
    padding: 100px 30px;
    position: relative;
    width: 60% !important;
 
    left:20%;
    top:50px;
    transform: translateY(-15%);
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 5px;
    margin: 50px auto;
    overflow: auto;
 
}


.head {
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    align-items: center;
    text-align: center;
    color: rgb(242, 127, 12);
}

.para {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: rgb(0, 65, 90);
}

.para2 {
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
    color: #454545;
}

.imgg {
    text-align: center;
}


.sub_btn {
    text-align: center;
}

.sub_btn  button{
   color: #fff;
    background: #F27F0C;
    border-radius: 8px;
    padding: 10px 60px;
    border: none;
}

@media (min-width:320px) and (max-width:767px)
    {
    .form1 form h3 {
      font-weight: 500;
      font-size: 19px;
      line-height: 15px;
     
    }
    .form1 input {
      padding: 3px 3px;
    }
    
    .form1 input::placeholder, .form2 input::placeholder{
    
    font-size: 12px !important;
    }
    
    .checkbox label {
      font-size: 10px !important;
      line-height: 15px !important;
      margin-bottom: 0px;
      margin-right: 10px;
      text-transform: none !important;
    }
    
    .sub_txt {
      text-align: right;
      padding-right: 20px;
      margin-top: 10px !important;
    }
    
    .sub_txt button {
      padding: 6px 26px;
    }
    .form2 {
      margin-top: 20px;
    }
    
    .contact ul li {
        margin: 0px 5px;
        display: inline-block;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01em;
        color: rgb(0, 65, 90);
    }
    
    .foot_text {
    
      font-size: 10px;
    
    }
    .box {
      padding: 45px 8px;
      position: relative;
      width: 85% !important;
      height: auto !important;
      left: 8%;
      top: 52px;
      transform: translateY(-10%);
      background-color: #fff;
      border: 1px solid #999;
      border-radius: 5px;
      margin: 50px auto;
      overflow: auto;
    }
    .imgg img {
      width: 60px;
      height: 60px;
  }
  .head {
    font-weight: 500;
    font-size: 15px !important;
    line-height: 35px;
    align-items: center;
    text-align: center;
    color: #F27F0C;
}

.para {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #00415A;
}

.para2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #454545;
}
.sub_btn button {
  color: #fff;
  background: #F27F0C;
  border-radius: 6px;
  padding: 7px 45px;
  border: none;
}

  }